<template>
    <div class="home">
      
       <div style="
            height: 20px;
            line-height: 60px;
            right: 42px;
            position: fixed;
            z-index: 1;
            text-align: right;">
            <i @click="gopublish('/article/publish')" class="el-icon-close"></i>
      </div>

    
      <div class="marigtn fle" style="padding-top: 20px;">
      </div>
      <div class="pt20"></div> 
    <div class="form">
        <div class="fle">
            <div>
              <h3 class="bgl" style="    margin-top: 0;">账号信息
              <el-button class="mj" @click="shouqi" v-show="btnshow" size="mini" type="info">收起</el-button>
                <el-button class="mj" @click="shouqi" v-show="!btnshow" size="mini" type="info">展开</el-button>
                </h3>
            </div>
            <div style="display: flex;" v-if="btnshow">
                <div>发布账号：
                  <template>
                    <el-select
                      v-model="user_id"
                      multiple
                      collapse-tags
                      style="margin-left: 20px;"
                      placeholder="请选择">
                      <el-option
                        v-for="item in Pushuser"
                        :key="item.keyid"
                        :label="item.nickname"
                        :value="item.keyid">
                      </el-option>
                    </el-select>
                  </template>
                </div>
                <!-- <div style="display:flex;">
                   <el-button style="height: 40px;" type="info">删除</el-button>
                   <el-button style="height: 40px;" type="primary">新增</el-button>
                </div> -->
            </div>
            
            <h3 style="margin-top: 0;" class="bgl">内容信息</h3>
            <div>
                <div>图像格式：
                  <template>
                    <el-radio v-model="radio" :label="1">图片</el-radio>
                  <!--  <el-radio v-model="radio" :label="2">视频</el-radio>-->
                  </template>
                  <div>(最多可以添加6张，支持单张图片小于10mb,格式为jpeg,png)<!--<el-button style="margin-left:20px;" type="info">编辑</el-button>--></div>
                   <div >
                     <draggable
                     style="display:flex"
                      class="list-group"
                      tag="ul"
                      v-model="fileList"
                      v-bind="dragOptions"
                      @start="drag = true"
                      @end="drag = false"	
                    >
                      <div class="block" style="padding: 0px;" v-for="(img,index) in fileList" :key="img.id">
                        <i @click.stop="deleimage(index)" class="el-icon-circle-close imgii"></i>
                        <el-image
                        style="width: 150px; height: 150px;padding: 0px;"
                        :src="img.src"
                        fit="fill">
                        </el-image>
                      </div>
                      
                    <div @click="setshowimages" class="el-upload el-upload--picture-card">
                      <i style="margin-top: 55px;margin-left: 10px;" class="el-icon-plus"></i>
                    </div>
                    </draggable>
		

                    
                   </div>

                   <el-dialog width="80%" title="新增图片" :visible.sync="showimages">
                       <fileimg v-if="reFresh"  :fileList='fileList' @getimgflie='getimgflie'></fileimg>
                  </el-dialog>
                    
                     <!-- <el-dialog  :visible.sync="dialogVisible">
                        <img width="100%" :src="dialogImageUrl" alt="">
                      </el-dialog>-->
    
                </div>
                <div>标题：
                  <template>
                    <el-input v-model="title" style="width:500px;" :maxlength='20' show-word-limit placeholder="最多输入40字符，20汉字；支持中英文、常用符合、emoji"></el-input>
                  </template>
                </div>
            <div>
                正文：
                <el-input
                    type="textarea"
                    placeholder="请输入内容"
                    v-model="content"
                    maxlength="1000"
                    show-word-limit
                    style="width:800px;"
                     :autosize="{ minRows: 4, maxRows: 8}"
                    >
                    </el-input>
            </div>
            <div>分类：
                  <template><!-- 文章分类列表acpotions 文章分类category   -->
                    <el-select v-model="category" filterable  placeholder="请选择">
                      <el-option
                        v-for="item in acpotions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-select>
                  </template>
                
                  <template v-if="category === 3">
                    航司：
                    <el-autocomplete
                        v-model="aircompany_airline"
                        :fetch-suggestions="querySearchAsync"
                        :trigger-on-focus="false"
                        placeholder="请输入内容"
                        @select="handleSelect"
                        ></el-autocomplete>
                  </template>
              </div>

              <div>
                话题：
                  <template>
                   <el-select
                      @change="Selecttopic"
                        v-model="topic_id"
                        filterable
                        remote
                        reserve-keyword
                        placeholder="请输入关键词"
                        :remote-method="remoteMethod"
                        v-loadmore="topicdrop"
                        :loading="loading">
                        <el-option
                        :class="item.topic_id==0?'red':''"
                        v-for="item in topicoptions"
                        :key="item.topic_id"
                        :label="item.name"
                        :value="item.topic_id">
                        </el-option>
                    </el-select>
                  </template>
                
                    定位：
                  <template>
                    <el-select
                        v-model="placestring"
                        filterable
                        remote
                        reserve-keyword
                        placeholder="请输入关键词"
                        :remote-method="getplace"
                        v-loadmore="placedrop"
                        @change="placeSelect"
                        :loading="placeloading">
                        <el-option
                        v-for="(item,index) in placeoptions"
                        :key="index"
                        :label="item.title"
                        :value="index">
                        </el-option>
                    </el-select>
                  </template>
                </div>

             <h3 class="bgl">文章数据</h3>
                  
                <div>追加获赞数：
                  <template>
                    <el-input @input="e => (add_like_num = isnumber(e))" v-model="add_like_num" style="width:150px;"  placeholder=""></el-input>
                  </template>
                    追加收藏数：
                  <template>
                    <el-input @input="e => (add_collection_num = isnumber(e))" v-model="add_collection_num" style="width:150px;"  placeholder=""></el-input>
                  </template>
                    发布时间:
                     <el-date-picker
                        v-model="timing"
                        type="datetime"
                        placeholder="选择日期">
                      </el-date-picker>
                  <template>
                    <el-checkbox v-model="is_recommend">评为精选文章</el-checkbox>
                  </template>
                </div>
            </div>
        </div>
        <div class="flex" >文章标签：
                <div v-for="label in label" :key="label.label_id">{{label?label.name:''}}{{label.length > 1 && label[label.length-1]!=label?'/':''}}</div>
                <el-button  class="ww" type="primary" @click="dialogTableVisible = true"  size="mini" icon="el-icon-edit"></el-button>
        </div>
        <el-dialog title="文章标签" :visible.sync="dialogTableVisible">
          <edlabel :label="label" @getlabel='getlabel'></edlabel>
        </el-dialog>
                <el-form ref="form" :model="form" label-width="180px">
            <el-form-item>
                <el-button v-if="!look" type="primary" style="margin-left: 25%;" @click="onSubmit(1)">存草稿</el-button>
                <!--<el-button v-if="!look" type="primary" style="" @click="onSubmit(2)">发布内容</el-button>-->
            </el-form-item>
        </el-form>
      <div class="pt20"></div>
        
      
    </div>
    
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'axios'
import config from '../../lib/config'
import formatDate from '../../lib/dateFormat'
import edlabel from '../model/label.vue'
import fileimg from '../model/selectimg.vue'
import draggable from 'vuedraggable'

export default {
  name: 'Home',
  components: {
    edlabel,
    fileimg,
    draggable
  },
  data(){
    return {
    user_id:[],
    fileList: [],
    title:'',//	文章标题
    content:'',//文章内容
    category:'',//所属分类
    topic_id:'',//	话题id
    lng:'',//经度
    lat:'',//纬度
    aircompany_id:'',//航空公司id
    location:'',//定位地址名称
    add_like_num:'',//点赞数
    add_collection_num:'',//	收藏数
    is_recommend:'',//	是否推荐 0否1是
    label:[],//标签
    timing:'',//定时时间
    //存草稿多4个参数
    aircompany_name:'',//航空公司名称
    topic_name:'',//话题名称
    label_name:'',//标签名

    reFresh:true,
    Pushuser:[],
    showimages:false,
    dialogTableVisible: false,
    placestring:'',
    placeloading:false,
    placequery:'',
    placepage:1,
    placelast_page:1,
    placeoptions:[],
    timevalue:'',
    loading: false,
    topicquery:'',
    topicpage:1,
    topiclast_page:1,
    topicoptions:[],
    restaurants:[],
    aircompany_airline:'',
    topicList_airline:'',
    aioptions:[],//航司列表
    acpotions:[],//文章分类
      checked:'',
      zidingyi:'',
      input:'',
      btnshow:true,
      look:false,
      imgsrcall:[],
      form: {
        id:0,
        created_at:'',
        user:{
            id:0,
        },
        category:{
            name:'',
        }, 
        topic:{
            name:'',
            belong:2,
        },  
        aircompany:{
            name:'',
        }, 
        article:{}
        },
        options2:[],
        islist:0,

        imageUrl: '',
        option:{
          size:1,
          outputType:'jpeg',
          fixedNumber:[1,1]
        },
        
        topicname:'',
        dialogImageUrl: '',
        dialogVisible: false,

        radio:1,
        value:'',
         options: [{
          value: '选项1',
          label: '黄金糕'
        }, {
          value: '选项2',
          label: '双皮奶'
        }, {
          value: '选项3',
          label: '蚵仔煎'
        }, {
          value: '选项4',
          label: '龙须面'
        }, {
          value: '选项5',
          label: '北京烤鸭'
        }],
    }
  },
  methods: {
      shouqi(){//展开收起
        this.btnshow = !this.btnshow;
      },
      isnumber (val) {
        var zz2 = new RegExp("^[0-9]*[1-9][0-9]*$");
          if(zz2.test(val)){
            return val;
          }
          return '';
      },
    deleimage(index){
      // if(this.option.id == this.fileList[index].id){
      //   this.option.img = '';
      //   this.option.id = '';
      // }
      this.fileList.splice(index, 1);
    },
        gopublish(url){
            this.$router.push(url) 
        },
        unshiftimage(index){
          this.fileList.unshift(this.fileList.splice(index , 1)[0]);
        },
     onSubmit(num) {
     let _this = this;
        
     if(_this.user_id.length === 0){
          _this.$message.error('发布账号必填！'); return;
      }
      if(_this.fileList.length === 0){
          _this.$message.error('图片必填！'); return;
      }
      if(_this.title === ''){
          _this.$message.error('文章标题必填！'); return;
      }
      if(_this.content === ''){
          _this.$message.error('文章内容必填！'); return;
      }
      if(_this.category === ''){
          _this.$message.error('所属分类必填！'); return;
      }
      if(_this.topic_id === ''){
          _this.$message.error('所属话题必填！'); return;
      }
      if(_this.category === 3 && _this.aircompany_id === ''){
          _this.$message.error('航空公司必填！'); return;
      }
      if(_this.add_like_num === ''){
        _this.add_like_num = 0;
        //_this.$message.error('点赞数必填！'); return;
      }
      if(_this.add_collection_num === ''){
        _this.add_collection_num = 0;
        // _this.$message.error('收藏数必填！'); return;
      }
      if(_this.label.length === 0){
          _this.$message.error('标签必填！'); return;
      }
          
      let postdata = {
          user_id:this.user_id,//用户id
          title:this.title,//	文章标题
          content:this.content,//文章内容
          category:this.category,//所属分类
          topic_id:this.topic_id,//	话题id
          aircompany_id:this.aircompany_id===''?0:this.aircompany_id,//航空公司id
          lng:this.lng,//经度
          lat:this.lat,//纬度
          location:this.location,//定位地址名称
          add_like_num:this.add_like_num,//点赞数
          add_collection_num:this.add_collection_num,//	收藏数
          is_recommend:this.is_recommend == true ? 1 : 0,//	是否推荐 0否1是
          timing:'',//定时时间
      };
      
     
      if(this.timing !== ''){
          postdata.timing = formatDate(this.timing)
      }
      //标签label:this.label,
      let label = [];
      let label_name = [];
      for(let i in _this.label){
         label.push(_this.label[i].id);
         label_name.push(_this.label[i].name);
      }
      postdata.label = label;
      //文件 file:this.fileList
      // let file = [];
      // for(let i in _this.fileList){
      //    file.push({
      //      file:_this.fileList[i].file,
      //      src:_this
      //    });
      // }
     //   postdata.file = file;
      
       let formdata = new FormData();
       let fors = 0;
        for(let i in _this.fileList){
          canvasDataURL(_this.fileList[i].src,_this.fileList[i].file);
        }
    //  _this.fileList.map(element => {
        
        // if(element.file.size > 1000000){
        //   let filez = canvasDataURL(element.src,element.file.name);
        //   formdata.append("file[]", filez);
        //   console.log(filez);
        // }else{
        //   formdata.append("file[]", element.file);
        // console.log(element.file);
        // }
    //    return
    //  });

  let setinter = setInterval(function(){
    if(fors == _this.fileList.length){
       axios.post(config.articleImageUpload,formdata,{headers:{'Content-Type':'multipart/form-data'}})
        .then(function (response) {
            if(response.data.code == 200){
                      postdata.images = response.data.data;
                      let url = ''
                      if(num === 1){//存草稿
                         url = config.articlePushDraftAdd;
                         postdata.topic_name = _this.topic_name;
                         postdata.aircompany_name = _this.aircompany_name;
                         postdata.label_name = label_name;
                      }
                      if(num === 2){//发布内容
                          url = config.articlePush;
                      }
                        axios.post(url,postdata)
                          .then(function (response) {
                              if(response.data.code == 200){
                                _this.$message({
                                  message: response.data.message,
                                  type: 'success'
                                });
                                location.Publishstatus = 2;
                                _this.goNav('/article/publish');
                              }else{
                                  _this.$message.error(response.data.message);
                              }
                            })
                          .catch(function (error) {
                            console.log(error);
                          });
            }else{
                _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      clearInterval(setinter);
    }
  },100);

     

        function canvasDataURL(path,file){
          if(file.size < 50000){//小于50kb不压缩
            formdata.append("file[]", file);
            fors++;
            return
          }
             var img = new Image();
             img.src = path;
             img.onload = function(){
              var that = this;
              // 默认按比例压缩
              var w = that.width,
               h = that.height;
              //生成canvas
              var canvas = document.createElement('canvas');
              var ctx = canvas.getContext('2d');
              // 创建属性节点
              var anw = document.createAttribute("width");
              anw.nodeValue = w;
              var anh = document.createAttribute("height");
              anh.nodeValue = h;
              canvas.setAttributeNode(anw);
              canvas.setAttributeNode(anh); 
              ctx.drawImage(that, 0, 0, w, h);
              
              var quality = 0.7;  // 默认图片质量为0.7
              // quality值越小，所绘制出的图像越模糊
              if(file.size>5000000){quality = 0.5;}
              if(file.size>10000000){quality = 0.3;}
              var base64 = canvas.toDataURL('image/jpeg', quality);
              // 回调函数返回base64的值
              
            var arr = base64.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
           
           var bl =  new Blob([u8arr], {type:mime});
           var filex =  new File([bl], file.name, {type: bl.type, lastModified: Date.now()});
           
            fors++;
            formdata.append("file[]", filex);
            }
        }
    
    },
      goNav(url){
          this.$router.push(url)
      },
      init(){
          let _this = this;
          _this.getarticleCategory();//获取文章分类
          _this.getuser();//获取用户列表
      },
      setshowimages(){
        this.showimages = true;
        this.reFresh= false;
        this.$nextTick(()=>{
              this.reFresh = true
        })
      },
      getimgflie(files){
        this.fileList = files;
        this.showimages = false;
      },
      getlabel(label){
            this.label = label
            this.dialogTableVisible = false;
      },
      querySearchAsync(queryString, cb) {
           axios.get(config.aircompany,{params:{query:queryString}})//获取航司列表
        .then(function (response) {
            if(response.data.code == 200){
              let arr= response.data.data.data.map((a) => {
                    return {
                    value: a.airline,
                    id: a.id,
                    };
                });
              cb(arr);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
      placedrop(){
          let _this = this;
          if(_this.placelast_page > _this.placepage){
            _this.placepage++;
            axios.get(config.place,{params:{keyword:_this.placequery,pageIndex:_this.placepage,lng:121.472644,lat:31.231706}})//获取定位列表 分页
            .then(function (response) {
                if(response.data.code == 200){
                _this.placeoptions = _this.placeoptions.concat(response.data.data.place);
                }
            })
            .catch(function (error) {
                 console.log(error);
            });
          }
      },
      topicdrop(){
          let _this = this;
          if(_this.topiclast_page > _this.topicpage){
            _this.topicpage++;
            axios.get(config.topicList,{params:{query:_this.topicquery,page:_this.topicpage}})//获取话题列表 分页
            .then(function (response) {
                if(response.data.code == 200){
                _this.topicoptions = _this.topicoptions.concat(response.data.data.data);
                }
            })
            .catch(function (error) {
                 console.log(error);
            });
          }
      },
      Selecttopic(e){
        let _this = this;
        for(let i in _this.topicoptions){
          if(_this.topicoptions[i].topic_id == e){
            _this.topic_name = _this.topicoptions[i].name;
          }
        }
        if(this.topicname === ''){return}
          axios.post(config.addTopic,{content:this.topicname})//添加话题
            .then(function (response) {
                if(response.data.code == 200){
                  
                    _this.topicoptions = [{name:_this.topicname,topic_id:response.data.data.topic_id}];
                  
                  setTimeout(function(){
                    _this.topic_id = response.data.data.topic_id;
                  },100);
                }
            })
            .catch(function (error) {
                 console.log(error);
            });
      },
      getplace(queryString){
        let _this = this;
          if (queryString !== '') {
            _this.placeloading = true;
            _this.placepage = 1;
            axios.get(config.place,{params:{keyword:queryString,pageIndex:_this.placepage,lng:121.472644,lat:31.231706}})//获取话题列表
            .then(function (response) {
                _this.placeloading = false;
                if(response.data.code == 200){
                _this.placequery = queryString;
                _this.placeoptions = response.data.data.place;
                _this.placelast_page = response.data.data.all_page_index;
                }
            })
            .catch(function (error) {
                _this.loading = false;
                 console.log(error);
            });
        }
      },
      remoteMethod(queryString) {
          let _this = this;
          if (queryString !== '') {
            _this.loading = true;
            _this.topicpage = 1;
            axios.get(config.topicList,{params:{query:queryString,page:_this.topicpage}})//获取话题列表
            .then(function (response) {
                _this.loading = false;
                if(response.data.code == 200){
                _this.topicquery = queryString;
                _this.topicoptions = response.data.data.data;
                if(_this.topicoptions.length === 0){
                  _this.topicname = queryString;
                  _this.topicoptions.push({name:queryString,topic_id:0});
                }else{
                  _this.topicname = '';
                }
                _this.topiclast_page = response.data.data.last_page;
                }
            })
            .catch(function (error) {
                _this.loading = false;
                 console.log(error);
            });
        }
      },
      handleSelect(item) {
        this.aircompany_id = item.id;
        this.aircompany_name = item.value;
      },
      placeSelect(item) {
        let place = this.placeoptions[item];
        this.lng = place.location.lng;
        this.lat = place.location.lat;
        this.location = place.title;
      },
      getarticleCategory(){//获取文章分类
          let _this = this;
        axios.get(config.articleCategory)
        .then(function (response) {
            if(response.data.code == 200){
              _this.acpotions  = response.data.data;
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
      getuser(){//获取文章分类
          let _this = this;
        axios.get(config.articlePushUserList)
        .then(function (response) {
            if(response.data.code == 200){
              _this.Pushuser  = response.data.data;
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      }
  },
  created(){
      this.init();
  },
  computed:{
    dragOptions() {
        return {
          animation: 200,
          group: "description",
          disabled: false,
          ghostClass: "ghost"
        };
      }
  }
}
</script>
<style scoped>
.form{
    margin-right: auto;
    text-align: left;
}
.flex{
    display: flex;
}
.left{
  margin-left: 30px;
}
.marigtn{
    margin-left: auto;
    width: 450px;
    text-align: left;
}  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
.pw{
    margin-right: 7px;
}
.el-icon-close:before{
  font-size: 24px;
}
 div{
    vertical-align: middle;
    font-size: 14px;
    color: #606266;
    line-height: 25px;
    padding: 0 12px 0 0;
    box-sizing: border-box;
}
.fle div{
    vertical-align: middle;
    font-size: 14px;
    color: #606266;
    line-height: 25px;
    padding: 0 12px 10px 0;
    box-sizing: border-box;
    font-weight: bold;
}
.fle div input{
  height: 20px;
}
.bgl{
    background-color: aliceblue;
    padding: 10px;
}
.red{
  color:red;
}
.imgii{
  position: absolute;
  right: -10px;
  z-index: 999;
  font-size: 24px;
  top: -10px;
}
.block{
  position: relative;margin-right: 15px;
}
</style>
